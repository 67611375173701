import OSS from 'ali-oss'
class OssClient {
    constructor() {
        this.client = new OSS({
            region: 'oss-ap-southeast-1',
            accessKeyId: 'LTAI5tFHsU82K46RA2iztzS8',
            accessKeySecret: 'nzvVJhBDK2g7f4gLU023dgDuCGgGeU',
            bucket: 'npn-mr'
        })
    }
    getNowFormatDate() {
        let date = new Date(),
            year = date.getFullYear(), //获取完整的年份(4位)
            month = date.getMonth() + 1, //获取当前月份(0-11,0代表1月)
            strDate = date.getDate() // 获取当前日(1-31)
        if (month < 10) month = `0${month}` // 如果月份是个位数，在前面补0
        if (strDate < 10) strDate = `0${strDate}` // 如果日是个位数，在前面补0
        return `${year}-${month}-${strDate}`
    }
    uploadFile(fileName,blobFile, callBack) {
        this.client.multipartUpload(fileName, blobFile).then(function (result) {
            callBack && callBack()
        }).catch((err) => {
            console.log(err)
        })
    }
}

export default OssClient