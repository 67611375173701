const Config = {
    local:{
        name: 'local',
        address: 'ws://127.0.0.1:8282',
    },
    sit: {
        name: 'sit',
        address: "wss://sit.npn.sg/wss",
    },
    uat: {
        name: 'uat',
        address: "ws://127.0.0.1:8282",
        ar:''
    },
    prod: {
        name: 'prod',
        address: "wss://nursesday2024.real-axe.com/wss",
    },
    run(env) {
        const environment = ['local','sit', 'uat', 'prod']
        if (environment.includes(env)) {
            return Config[env]
        }
        return Config.local
    }
}
export default Config