export const isGPUSupported = () => {
    try {
        var canvas = document.createElement('canvas');
        var gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
        if (gl && gl instanceof WebGLRenderingContext) {
            var extensions = gl.getSupportedExtensions();
            if (extensions.indexOf('WEBGL_draw_buffers') !== -1) {
                return true;
            }
        }
    } catch (e) { }
    return false;
}


export const isSupportsWebGL = () => {
    try {
        var canvas = document.createElement('canvas');
        return !!window.WebGLRenderingContext && (canvas.getContext('webgl') || canvas.getContext('experimental-webgl'));
    } catch (e) {
        return false;
    }
}

export let ios = () => {
    return !!window.navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
}

export let vmin = (num) => {
    let w = document.documentElement.clientWidth;
    let h = document.documentElement.clientHeight;
    return parseFloat(Math.min(w, h) / 100 * num).toFixed(2) + "vmin";
}
export let initDepartment = (data) => {
    let departments = []
    for (let i = 0; i < data.length; i++) {
        let item = data[i]
        item['index'] = i + 1
        departments.push(item)
    }
    return departments
}
export let departmentSort = (departments) => {
    departments.sort((a, b) => {
        if (parseFloat(a.distance) == parseFloat(b.distance)) {
            return parseInt(a.id) - parseInt(b.id)
        }
        return parseFloat(b.distance) - parseFloat(a.distance);
    })
    let rank = 1
    let distance = 0
    let same = 1
    for (let i = 0; i < departments.length; i++) {
        let item = departments[i]
        if (i = 0) {
            distance = item.distance
        } else {
            if (distance != item.distance) {
                rank += same
                same = 1
                distance = item.distance
            } else {
                same++
            }
        }
        item['rank'] = rank
        item['positonIndex'] = i + 1
    }
    return departments
}


export const SendNavigatorShareMessage = (data,base64url) => {
    if (!window.navigator.share) return
    const title = document.querySelector('title').innerText.trim()
    const blobFile = new File([data], `${title}.png`, { type: 'image/png', lastModified: Date.now() })
    let url = new URL(window.location.href);
    url.hash = '#share_' + base64url
    const shareUrl = url.href
    if (window.navigator.canShare({ files: [blobFile] })) {
        window.navigator.share({
            url: shareUrl,
            title: title,
            text: '',
            files: [blobFile]
        })
    } else {
        window.navigator.share({
            url: shareUrl,
            title: title,
            text: title,
            url: window.location.href
        })
    }
}