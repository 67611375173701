import { FaceLandmarker, FilesetResolver } from "@mediapipe/tasks-vision"
import WebCamera from "./core/webcamera.js";
import { ios, isGPUSupported, isSupportsWebGL } from "./core/util.js";
import PageManage from "./core/PageManage.js";
import Config from "./common/config.js";
import Session from "./common/session.js";
import Socket from "./common/socket.js";
import SOCKET_ACTION from "./common/socketaction.js";
let isSupport = false
if (isSupportsWebGL()) {
    isSupport = true
}
let env = 'prod'
const config = Config.run(env)
const session = new Session()
let detectoring = false
let pledgeInited = false
let pledgeTracking = false
let choice = 0
let openSuccess = false
let drawCamera;
let accessCamera = true
let socketReconnect = false
let pledgeWorker = null
let loadMediapipe = null
let TrackingFunc;
let pledgeFunc;
if (isSupport) {
    try {
        let testCanvas = document.createElement("canvas")
        testCanvas.width = 320
        testCanvas.height = 320
        let testCtx = testCanvas.getContext('2d',{ willReadFrequently: true });
        testCtx.fillRect(0,0,testCanvas.width, testCanvas.height);
        pledgeWorker = new Worker('./core/pledgeworker.js');
        pledgeWorker.postMessage({ type: 'init' })
        pledgeWorker.onmessage = (e) => {
            switch (e.data.type) {
                case 'init':
                    pledgeInited = true
                    let testImageData = testCtx.getImageData(0,0,testCanvas.width, testCanvas.height);
                    pledgeWorker.postMessage({ type: 'test', 'imagedata': testImageData })
                    break;
                case 'pledge':
                    const { status } = e.data
                    if (status) {
                        console.log("pledge")
                        detectoring = false
                        pledgeFunc && pledgeFunc()
                    } else {
                        detectoring = true
                    }
                    break;
            }
        }
    } catch (e) {
        accessCamera = false
    }
}
let socket = null;
let lockTimer = null
let receiveCallback = (message) => {
    let data = JSON.parse(message.data)
    const { code, status } = data
    if (code != 200) { //非法访问
        return
    }
    console.log(data)
    switch (status) {
        case SOCKET_ACTION.PING:
            socket.send(SOCKET_ACTION.PING, 'pong')
            break;
        case SOCKET_ACTION.WELCOME:
            if (data.message.departments.length > 0) {
                let departments = data.message.departments
                PageManage.initDepartmentData(departments, data.message.end)
                if (!data.message.end) {
                    let endTime = parseInt(data.message.endtime)
                    let timeStamp = parseInt((new Date()).getTime() / 1000)
                    if (endTime > timeStamp) {
                        if (lockTimer) {
                            clearInterval(lockTimer)
                            lockTimer = null
                        }
                        lockTimer = setInterval(() => {
                            timeStamp += 2
                            if (timeStamp >= endTime) {
                                clearInterval(lockTimer)
                                lockTimer = null
                                PageManage.Over()
                            }
                        }, 2000)
                    } else {
                        PageManage.Over()
                    }
                }
            }
            PageManage.joinRoom(socketReconnect)
            break;
        case SOCKET_ACTION.MOBILE_JOIN:
        case SOCKET_ACTION.SIGN:
        case SOCKET_ACTION.CHOISE_MENU:
        case SOCKET_ACTION.OVER:
            PageManage.applyHandle(status, data.message)
            break;
        case SOCKET_ACTION.INCREASE:
            let department = parseInt(data.message.department)
            let played_num = parseInt(data.message.played_num)
            PageManage.updateDepartment({ id: department, distance: played_num,endtime:data.message.end_time })
            break;
        case SOCKET_ACTION.REFLUSH:
            session.clear()
            let url = new URL(window.location.href);
            window.location.href = url.href
            window.location.reload()
            break;
        case SOCKET_ACTION.END:
            if (lockTimer) {
                clearInterval(lockTimer)
                lockTimer = null
            }
            PageManage.Over()
            break;
    }

}
document.addEventListener("DOMContentLoaded", () => {
    socket = new Socket(config.address, () => { }, receiveCallback, () => {
        setTimeout(() => {
            socketReconnect = true
            socket.reconnect()
        }, 2000)
    }, () => { });

    loadMediapipe && loadMediapipe()


    PageManage.init(socket, config, session)

    socket.connect()
});

let OpenCamera = () => {
    if(PageManage.isSimple){
        PageManage.notSuport()
        return
    }
    if(!accessCamera){
        PageManage.notSuport()
        return
    }
    if (openSuccess && !PageManage.isSimple) {
        PageManage.showPledge()
        return
    }
   
    let videoContainer = document.querySelector('#WebCamera')
    let canvasContainer = document.querySelector("#WebCanvas")
    let webCamera = new WebCamera(videoContainer, true, ios(), canvasContainer)
    webCamera.openCamera((draw) => {
        openSuccess = true
        PageManage.showPledge()
        PageManage.isSimple = false
        drawCamera = draw
    }, () => {
        openSuccess = false
        PageManage.notSuport()
        // 关闭web worker线程
        pledgeWorker && pledgeWorker.terminate()
    })
}

let faceDetector;
let faceTracking = false
loadMediapipe = async () => {
    try {
        let dirPath = "mediapipe"
        const vision = await FilesetResolver.forVisionTasks(dirPath)
        faceDetector = await FaceLandmarker.createFromOptions(vision, {
            baseOptions: {
                modelAssetPath: `${dirPath}/face_landmarker/face_landmarker.task`,
                delegate: isGPUSupported() ? "GPU" : "CPU"
            },
            runningMode: "VIDEO",
            outputFaceBlendshapes: true,
            numFaces: 1
        })
    } catch (e) {
        accessCamera = false
    }
}



let monthStatus = -1
let eyeStatus = -1
let browUp = -1
let eyeTimer = null
let blinkEyeLiveTime = 0


let FaceAnalysis = async (data) => {
    switch (choice) {
        case 2:
            if (data[3].score > 0.7) {
                if (browUp != 1) {
                    console.log("borw up")
                    TrackingFunc(1)
                    browUp = 1
                }
                setTimeout(() => {
                    detectoring = true;
                }, 500)
            } else {
                if (browUp == 1) {
                    TrackingFunc(0)
                    browUp = 0
                }

                detectoring = true;
            }
            break;
        case 3:
            if (data[25].score > 0.4) {
                monthStatus = 1
                TrackingFunc(1)
            } else if (data[25].score < 0.1) {
                if (monthStatus == 1) {
                    monthStatus = 0
                    TrackingFunc(0)
                    console.log("month close")
                    setTimeout(() => {
                        detectoring = true;
                    }, 500)
                    break;
                }
            }
            detectoring = true;
            break;
        case 4:
            if (data[9].score > 0.6 && data[10].score > 0.6) {
                if (eyeStatus != 1) {
                    eyeStatus = 1
                    blinkEyeLiveTime = 0
                    eyeTimer = setTimeout(() => {
                        blinkEyeLiveTime++
                        TrackingFunc(0)
                        console.log("eye close")
                    }, 1000)
                }
            } else if (data[9].score < 0.2 && data[10].score < 0.2 && eyeStatus == 1) {
                if (eyeStatus == 1) {
                    eyeStatus = 0
                    if (eyeTimer) {
                        clearTimeout(eyeTimer)
                        eyeTimer = null
                    }
                    if (blinkEyeLiveTime > 0) {
                        TrackingFunc(1)
                        detectoring = false
                        console.log("eye open")
                        break
                    }
                }
            }
            detectoring = true;
            break;
        default:
            detectoring = true;
            break;
    }
}


PageManage.onClick = (name, callback, params) => {
    TrackingFunc = null
    switch (name) {
        case "openCamera":
            OpenCamera()
            break;
        case "StartPledge":
            detectoring = true
            pledgeTracking = true
            callback && (pledgeFunc = callback)
            break;
        case 'StopPledge':
            detectoring = false
            pledgeTracking = false
            break;
        case "StartTracking":
            detectoring = true
            faceTracking = true
            if (params) {
                choice = params.choice
            }
            callback && (TrackingFunc = callback)
            break;
        case "StopTracking":
            faceTracking = false
            detectoring = false
            choice = 0
            monthStatus = -1
            eyeStatus = -1
            browUp = -1
            break;
    }

}

let faceDetectFunc = async(imageData,t)=>{
    const result = faceDetector.detectForVideo(imageData, t);
    let { faceBlendshapes } = result
    if (faceBlendshapes.length > 0) {
        const categories = faceBlendshapes[0].categories
        FaceAnalysis(categories)
    } else {
        detectoring = true;
    }
}

let tick = (t) => {
    if (!PageManage.isOver && openSuccess) {
        if (detectoring) {
            if (pledgeInited && pledgeTracking) {
                let imageData = drawCamera(true)
                detectoring = false;
                pledgeWorker.postMessage({ type: 'pledge', 'imagedata': imageData })
            } else if (faceDetector && faceTracking) {
                let imageData = drawCamera(true)
                detectoring = false;
                faceDetectFunc(imageData,t)
            } else {
                drawCamera()
            }
        } else {
            drawCamera()
        }
    }
    requestAnimationFrame(tick)
}
tick()