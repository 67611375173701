const GlobalMap = new Set()
class Session {
    constructor() {
        this.prefix = "nurse_mobile_session_"
        this._init()
    }
    /**
     * 
     * @param {string} key 
     * @param {string} value 
     */
    set(key, value) {
        const { prefix } = this
        if (!key.startsWith(prefix)) {
            key = `${prefix}${key}`
        }
        if (key in GlobalMap) {
            return
        }
        localStorage.setItem(key, value)
        GlobalMap[key] = value;
    }
    /**
    * 
    * @param {string} key 
    * @param {string} value 
    */
    update(key, value) {
        const { prefix } = this
        if (!key.startsWith(prefix)) {
            key = `${prefix}${key}`
        }
        localStorage.setItem(key, value)
        GlobalMap[key] = value;
    }
    /**
     * 
     * @param {string} key 
     * @param {string} defaultValue
     * @returns string
     */
    get(key,defaultValue = "") {
        const { prefix } = this
        if (!key.startsWith(prefix)) {
            key = `${prefix}${key}`
        }
        if (key in GlobalMap) {
            return GlobalMap[key]
        }
        let value = localStorage.getItem(key)
        if (value) {
            GlobalMap[key] = value
            return value
        }
        return defaultValue
    }
    _init() {
        const { prefix } = this
        if (GlobalMap.size > 0) return
        new Promise(_ => {
            let keys = Object.keys(localStorage);
            keys = keys.filter(key => {
                return key.startsWith(prefix)
            })
            keys.map(key => {
                if (key.startsWith(prefix)) {
                    let value = localStorage.getItem(key);
                    GlobalMap[key] = value;
                }
            })
        })

    }
    clear() {
        const { prefix } = this
        let keys = Object.keys(localStorage);
        keys = keys.filter(key => {
            return key.startsWith(prefix)
        })
        keys.map(key => {
            localStorage.removeItem(key)
        })
        GlobalMap.clear()
    }
    toString() {
        return JSON.stringify(GlobalMap);
    }
}
export default Session;