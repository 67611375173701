import OssClient from "./OssClient.js"
import { SendNavigatorShareMessage } from "./util.js"
import SOCKET_ACTION from "../common/socketaction.js"
import MobileDetect from "mobile-detect"
const departmentScore = require('../common/department.json')
const LOGINED_KEY = "nurse-login"
const USER_TOKEN = "nurse-user-token"
const LOGIN_TOKEN = "nurse-login-token"
const USER_NAME_KEY = 'nurse-user-name'
const GAME_TOKEN = 'nurse-game-token'
const PERSON_MEDAL = "person_medal"
const PROJECT_END = "project_end"
const DEPARTMENT_KEY = 'department-key'
const SCALE_DURING_TIME = 510
const PageShow = (container) => {
    container.classList.remove('hide')
}
const PageHide = (container) => {
    container.classList.add('hide')
}
const ElementScaleIn = (ele, callback) => {
    PageShow(ele)
    let animatedFunc = () => {
        ele.classList.remove('no-scale')
        ele.classList.remove('scale-fade-in')
        callback && callback()
    }
    ele.classList.add('scale-fade-in')
    setTimeout(() => {
        animatedFunc()
    }, 510)
}
const ElementScaleOut = (ele, callback) => {
    let animatedFunc = () => {
        PageHide(ele)
        ele.classList.remove('scale-fade-in')
        ele.classList.remove('scale-fade-out')
        ele.classList.add('no-scale')
        callback && callback()
    }
    ele.classList.add('scale-fade-out')
    setTimeout(() => {
        animatedFunc()
    }, 510)
}


const GameState = {
    currentGame: [],
    imageCdn: "https://static.mr.npn-tech.com/",
    timeStamp() {
        return parseInt(new Date().getTime() / 1000);
    },
    getGamedString() {
        return PageManage.session.get(PERSON_MEDAL)
    },
    getGamedArray() {
        let gameString = this.getGameString()
        if (gameString) {
            return gameString.split(",")
        }
        return []
    },
    makeSign() {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
        let start = ''
        for (let i = 0; i < 4; i++) {
            start += characters.charAt(Math.floor(Math.random() * characters.length))
        }
        let end = ''
        for (let i = 0; i < 4; i++) {
            end += characters.charAt(Math.floor(Math.random() * characters.length))
        }
        return start + '' + this.timeStamp() + '' + end
    },
    getNowFormatDate() {
        let date = new Date(),
            year = date.getFullYear(), //获取完整的年份(4位)
            month = date.getMonth() + 1, //获取当前月份(0-11,0代表1月)
            strDate = date.getDate() // 获取当前日(1-31)
        if (month < 10) month = `0${month}` // 如果月份是个位数，在前面补0
        if (strDate < 10) strDate = `0${strDate}` // 如果日是个位数，在前面补0
        return `${year}-${month}-${strDate}`
    },
    makeUploadFile() {
        let fileName = "nuh/screen/" + this.getNowFormatDate() + "/" + (new Date()).getTime() + "_" + (Math.random() * 10000) + ".png"
        const buff = Buffer.from(fileName, 'utf-8');
        let base64FileName = buff.toString('base64');
        return { fileName, base64FileName }
    }
}
const ScreenShot = {
    init() {
        this.index = 0
        this.imageSet = []
        this.container = document.body
    },
    setImageSet(images) {
        this.imageSet = images
    },
    reset() {
        this.index = 0
    },
    takePic(callback) {
        let that = this
        const { container } = this
        html2canvas(container).then(canvas => {
            let base64Url = canvas.toDataURL('image/jpeg')
            if (that.imageSet.length > 0) {
                that.imageSet[that.index].setAttribute('src', base64Url)
                that.index++
            }
            callback && callback()
            setTimeout(() => {
                PageManage.nextPage()
            }, SCALE_DURING_TIME)
        });
    }
}
const SharePage = {
    init() {
        this.container = document.querySelector('#share-container')
        const shareLink = this.container.querySelector('.share-link')
        this.imageTag = this.container.querySelector("#share-image-tag")
        shareLink.addEventListener('click', () => {
            this.container.classList.add('hide')
            let projectEnd = PageManage.session.get(PROJECT_END)
            if (projectEnd) {
                // 项目结束
                PageManage.Over()
                return
            }
            let userLogin = PageManage.session.get(LOGINED_KEY)
            if (userLogin) {
                //已登录
                ScoreBoardPage.show()
            } else {
                //未登录
                IndexPage.show()
            }
        })
    },
    setImage(url) {
        const { imageTag } = this
        imageTag.setAttribute("src", url + "?x-oss-process=image/resize,w_750")
        this.show()
    },
    show() {
        const { container } = this
        PageShow(container)
    },
    hide() {
        const { container } = this
        PageHide(container)
    },
}
const LoadPage = {
    init() {
        this.container = document.querySelector('#loading-page')
    },
    show() {
        const { container } = this
        PageShow(container)
    },
    hide() {
        const { container } = this
        PageHide(container)
    },
}
class Department {
    ele;
    prefixClass = "rank-";
    constructor(container, id, index, name, rank, positonIndex, distance, delay = 500) {
        this.id = id;
        // 初始化 索引主要用于颜色绑定
        this.index = index;
        // 名次
        this.name = name;
        // 排行
        this.rank = rank;
        // 位置 作用用于区别 相同排名的
        this.positonIndex = positonIndex;
        // 距离 
        this.distance = distance;
        this.container = container;
        this.delay = delay
        this.initHtml()
    }
    initHtml() {
        const { container, id, index, name, rank, positonIndex, distance, prefixClass, delay } = this
        let div = document.createElement('div')
        div.classList.add('item')
        div.classList.add(`${prefixClass}${positonIndex}`)
        div.setAttribute('data-id', id)
        div.setAttribute('data-distance', distance)
        div.setAttribute('data-rank', rank)
        let className = `icon-win-${index}`
        div.innerHTML = `<div class="rank-num-box"><span class="icon ${className}">${rank}.</span></div><div class="rank-team">${name}</div>`
        this.ele = div
        setTimeout(() => {
            container.appendChild(div)
        }, delay)
        this.rankTextE = div.querySelector(`.${className}`)

    }
    update(rank, positonIndex, distance) {
        const { prefixClass, ele, rankTextE } = this
        if (this.positonIndex != positonIndex) {
            let newClass = `${prefixClass}${positonIndex}`
            let oldClass = `${prefixClass}${this.positonIndex}`
            ele.classList.add(newClass)
            setTimeout(() => {
                ele.classList.remove(oldClass)
            }, 300)
            this.positonIndex = positonIndex
        }
        ele.setAttribute('data-distance', distance)

        if (this.rank != rank) {
            rankTextE.innerText = rank + "."
            this.rank = rank
            ele.setAttribute('data-rank', rank)
        }
    }
}
const DepartmentState = {
    initDepartment(data) {
        let departments = []
        for (let i = 0; i < data.length; i++) {
            let item = data[i]
            item['index'] = i + 1
            departments.push(item)
        }
        this.initData = departments
    },
    setInitData(departments) {
        this.initData = departments
    },
    sortDepartment() {
        const { initData } = this
        let departments = JSON.parse(JSON.stringify(initData));

        for (let i = 0; i < departments.length; i++) {
            let item = departments[i]
            let scoreInfo = departmentScore[item.id + ""].range
            let distance = parseInt(item.distance)
            let score = 100
            for (let i = 0; i < scoreInfo.length; i++) {
                if (distance <= scoreInfo[i]) {
                    if (i == 0) {
                        score = parseFloat(5 / scoreInfo[i] * distance).toFixed(2)
                    } else {
                        let min = scoreInfo[i - 1]
                        score = parseFloat(5 * i + (5 / (scoreInfo[i] - min)) * (distance - min)).toFixed(2)
                    }
                    break;
                }
            }
            if (score > 100) {
                score = 100
            }
            item['score'] = score
        }

        departments.sort((a, b) => {
            if (parseFloat(a.score) == parseFloat(b.score)) {
                let t1 = parseInt(a.end_time)
                let t2 = parseInt(b.end_time)
                if (t1 > 0 && t2 > 0) {
                    return t1 - t2
                } else if (t1 > 0 && t2 == 0) {
                    return -1
                } else if (t2 > 0 && t1 == 0) {
                    return 1
                }
                return parseInt(a.id) - parseInt(b.id)
            }
            return parseFloat(b.score) - parseFloat(a.score);
        })
        let rank = 1
        let score = 0
        let same = 1
        for (let i = 0; i < departments.length; i++) {
            let item = departments[i]
            if (i == 0) {
                score = item.score
            } else {
                if (score != item.score) {
                    //rank += same
                    rank++;
                    same = 1
                    score = item.score
                } else {
                    same++
                }
            }
            item['rank'] = rank
            item['positonIndex'] = i + 1
        }
        return departments;
    },
    updateDepartment(data) {
        for (let i = 0; i < this.initData.length; i++) {
            if (this.initData[i].id == data.id) {
                this.initData[i].distance = data.distance
                this.initData[i].end_time = data.endtime
                break;
            }
        }
    }
}
const IndexPage = {
    Rank: {},
    init() {
        this.container = document.querySelector('#index-container')
        this.rankContainer = this.container.querySelector('.rank-items')
        const beginBtnE = this.container.querySelector('.begin-btn')
        beginBtnE.addEventListener('click', () => {
            FormPage.show()
            this.hide()
        })
        this.runningMap = {}
        for (let i = 1; i < 11; i++) {
            this.runningMap[i] = this.getRunner(i)
        }
        this.inited = false
        this._initDepartment()
    },
    getRunner(number) {
        const { container } = this
        let runnerEl = container.querySelector(`.start-point-${number}`)
        let distanceE = runnerEl.querySelector('.perons-distance')
        let updateDistance = (score, dis) => {
            let perent = parseFloat(score / 100 * 3).toFixed(2)
            perent *= -1
            runnerEl.style.setProperty('--delay', `${perent}s`)
            let content = "";
            if (dis >= 10000) {
                content = "10.0KM"
            } else if (dis > 1000) {
                content = parseFloat(dis / 1000).toFixed(2) + "KM"
            } else {
                content = parseFloat(dis).toFixed(1) + 'M'
            }
            distanceE.innerText = content;
        }
        return { updateDistance }
    },
    show() {
        const { container } = this
        PageShow(container)
    },
    hide() {
        const { container } = this
        PageHide(container)
    },
    initDepartmentData(data) {
        if (data.length == 0) return
        const { rankContainer, runningMap, inited } = this
        if (!inited) {
            FormPage.initSelectBox(data)
            DepartmentState.initDepartment(data)
            let departments = DepartmentState.sortDepartment()
            PageManage.session.update(DEPARTMENT_KEY, JSON.stringify(departments))
            for (let i = 0; i < departments.length; i++) {
                let item = departments[i]
                let per = departmentScore[item.id + ""].per
                this.Rank[item.id] = new Department(rankContainer, item.id, item.index, item.name, item.rank, item.positonIndex, item.distance, i * 500)
                runningMap[item.index].updateDistance(item.score, item.distance * per)
            }
            ScoreBoardPage.initDepartmentData(departments)
        } else {
            data.forEach((item) => {
                DepartmentState.updateDepartment(item)
            })
            let departments = DepartmentState.sortDepartment()
            this._updateDepartment(departments)
            ScoreBoardPage.updateDepartment(departments)
        }
    },
    _initDepartment() {
        let departmentsStr = PageManage.session.get(DEPARTMENT_KEY, "")
        if (!departmentsStr) return
        const { rankContainer, runningMap } = this
        this.inited = true
        let departments = JSON.parse(departmentsStr);
        FormPage.initSelectBox(departments)
        DepartmentState.setInitData(departments)
        departments.forEach((item, i) => {
            let per = departmentScore[item.id + ""].per
            this.Rank[item.id] = new Department(rankContainer, item.id, item.index, item.name, item.rank, item.positonIndex, item.distance, i * 500)
            runningMap[item.index].updateDistance(item.score, item.distance * per)
        })
        ScoreBoardPage.initDepartmentData(departments)
    },
    updateDepartment(departmentInfo) {
        DepartmentState.updateDepartment(departmentInfo)
        let departments = DepartmentState.sortDepartment()
        this._updateDepartment(departments)
        ScoreBoardPage.updateDepartment(departments)
    },
    _updateDepartment(departments) {
        const { Rank, runningMap } = this
        PageManage.session.update(DEPARTMENT_KEY, JSON.stringify(departments))
        departments.forEach((item) => {
            let per = departmentScore[item.id + ""].per
            Rank[item.id].update(item.rank, item.positonIndex, item.distance)
            runningMap[item.index].updateDistance(item.score, item.distance * per)
        });
    }
}

const ScoreBoardPage = {
    Rank: {},
    init() {
        this.container = document.querySelector('#scoreboard-container')
        this.rankContainer = this.container.querySelector('.rank-items')
        const pledgeBtnE = this.container.querySelector('.pledge-btn')

        pledgeBtnE.addEventListener('click', () => {
            ChoicePage.show()
            this.hide()
        })
        this.runningMap = {}
        for (let i = 1; i < 11; i++) {
            this.runningMap[i] = this.getRunner(i)
        }
        const bottomContainer = this.container.querySelector('.bottom-container')
        this.medalItemSet = bottomContainer.querySelectorAll('.medal-item')
    },
    getRunner(number) {
        const { container } = this
        let runnerEl = container.querySelector(`.start-point-${number}`)
        let distanceE = runnerEl.querySelector('.perons-distance')
        let updateDistance = (score, dis) => {
            let perent = parseFloat(score / 100 * 3).toFixed(2)
            perent *= -1
            runnerEl.style.setProperty('--delay', `${perent}s`)
            let content = "";
            if (dis >= 10000) {
                content = "10.0KM"
            } else if (dis > 1000) {
                content = parseFloat(dis / 1000).toFixed(2) + "KM"
            } else {
                content = parseFloat(dis).toFixed(1) + 'M'
            }
            distanceE.innerText = content;
        }
        return { updateDistance }
    },
    show() {
        const { container } = this
        PageShow(container)
    },
    hide() {
        const { container } = this
        PageHide(container)
    },
    initDepartmentData(departments) {
        const { rankContainer, runningMap } = this

        departments.forEach((item, i) => {
            let per = departmentScore[item.id + ""].per
            this.Rank[item.id] = new Department(rankContainer, item.id, item.index, item.name, item.rank, item.positonIndex, item.distance, i * 500)
            runningMap[item.index].updateDistance(item.score, item.distance * per)
        })
    },
    updateDepartment(departments) {
        const { Rank, runningMap } = this
        departments.forEach((item) => {
            let per = departmentScore[item.id + ''].per
            Rank[item.id].update(item.rank, item.positonIndex, item.distance)
            runningMap[item.index].updateDistance(item.score, item.distance * per)
        });
    },
    updateMedal() {
        let medalString = PageManage.session.get(PERSON_MEDAL, "")
        if (!medalString) return
        const { medalItemSet } = this
        let medalArr = medalString.split(',')
        for (let i = 0; i < medalArr.length; i++) {
            let chose = parseInt(medalArr[i])
            medalItemSet[chose - 1].classList.add("active")
        }
    }
}

const FormPage = {
    init() {
        this.container = document.querySelector('#form-container')
        this.selectBoxE = this.container.querySelector('#select-content')
        this.departmentId = 0
        const submitE = this.container.querySelector('.form-btn')
        let nameE = this.container.querySelector('.form-input[name="name"]')
        let nameParentE = nameE.parentNode.parentNode
        let employeeIdE = this.container.querySelector('.form-input[name="employee-id"]')
        let employeeIdParentE = employeeIdE.parentNode.parentNode
        nameE.addEventListener('focus', () => {
            nameParentE.classList.remove('show-error')
        })
        employeeIdE.addEventListener('focus', () => {
            employeeIdParentE.classList.remove('show-error')
        })
        let clicked = false
        submitE.addEventListener('click', () => {
            if (clicked) return
            if (this.departmentId == 0) {
                dropItemE.parentNode.classList.add("show-error")
            }
            let name = nameE.value.trim()
            if (!name) {
                nameParentE.classList.add('show-error')
            }
            let employeeId = parseInt(employeeIdE.value.trim())
            if (!employeeId) {
                employeeIdParentE.classList.add('show-error')
            }
            LoadPage.show()
            clicked = true
            let params = {
                department_id: this.departmentId,
                username: name,
                employee_no: employeeId,
                'scan': PageManage.scan,
                'os': PageManage.os
            }
            let callback = (data) => {
                let { next } = data
                clicked = false
                this.hide()
                LoadPage.hide()
                if (next == 1) {
                    ChoicePage.show()
                    PageManage.session.update(USER_TOKEN, data.user_token)
                    PageManage.session.update(LOGIN_TOKEN, data.login_token)
                    PageManage.session.update(USER_NAME_KEY, name)
                    PageManage.session.update(LOGINED_KEY, GameState.timeStamp())
                }
            }
            PageManage.pushHandle(SOCKET_ACTION.SIGN, params, callback)
        })
    },
    initSelectBox(departments) {
        const { selectBoxE } = this
        let html = []
        departments.forEach(item => {
            html[item.id] = `<div class="option-item" data-value="${item.id}">${item.name}</div>`
        })
        selectBoxE.innerHTML = html.join("")
        const dropItemE = this.container.querySelector('.drop-item')
        let dropHide = () => {
            dropItemE.classList.add('fadeout')
            setTimeout(() => {
                dropItemE.classList.remove('fadein')
            }, 310)
        }
        let dropShow = () => {
            setTimeout(() => {
                dropItemE.classList.add('fadein')
                dropItemE.classList.remove('fadeout')
            }, 310)
        }
        dropItemE.addEventListener('click', (e) => {
            e.stopPropagation()
            e.preventDefault();
            if (dropItemE.classList.contains('fadein')) {
                dropHide()
            } else {
                dropShow()
            }
        })
        this.container.addEventListener('click', (e) => {
            e.stopPropagation()
            e.preventDefault();
            if (dropItemE.classList.contains('fadein')) {
                dropHide()
            }
        })
        let optionEs = dropItemE.querySelectorAll('.option-item')
        let labelE = this.container.querySelector('.form-label')
        for (let i = 0; i < optionEs.length; i++) {
            optionEs[i].addEventListener('click', (e) => {
                e.stopPropagation()
                e.preventDefault();
                dropHide()
                labelE.innerText = optionEs[i].innerText
                this.departmentId = parseInt(optionEs[i].getAttribute('data-value'))
                dropItemE.classList.add('selected')
                dropItemE.parentNode.classList.remove("show-error")
            })
        }
    },
    show() {
        const { container } = this
        PageShow(container)
    },
    hide() {
        const { container } = this
        PageHide(container)
    },
}

const ChoicePage = {
    isShow: false,
    init() {
        this.container = document.querySelector('#choice-container')
        let itemEs = this.container.querySelectorAll('.choice-item')
        this.isShow = false
        for (let i = 0; i < itemEs.length; i++) {
            let itemE = itemEs[i]
            itemE.addEventListener('click', () => {
                if (itemE.classList.contains('active')) {
                    itemE.classList.remove('active')
                } else {
                    itemE.classList.add('active')
                }
            })
        }
        this.itemEs = itemEs
        const submitE = this.container.querySelector('.submit-btn')
        let clicked = false
        submitE.addEventListener('click', (e) => {
            e.stopPropagation()
            e.preventDefault()
            let ids = []
            GameState.currentGame = []
            for (let i = 0; i < itemEs.length; i++) {
                let itemE = itemEs[i]
                if (itemE.classList.contains('active')) {
                    ids.push(parseInt(itemE.getAttribute('data-value')))
                }
            }
            if (ids.length == 0) return
            if (clicked) return
            clicked = true
            //发送请求
            GameState.currentGame = ids
            let login_token = PageManage.session.get(LOGIN_TOKEN)
            let params = {
                login_token,
                games: ids.join(','),
            }
            LoadPage.show()
            let callback = (data) => {
                clicked = false


                let { next } = data
                if (next == 1) {
                    StaticShotPage.updateMedals(ids)
                    PageManage.session.update(GAME_TOKEN, data.game_token)
                    PageManage.onClick('openCamera')
                }
            }

            PageManage.pushHandle(SOCKET_ACTION.CHOISE_MENU, params, callback)
        })
    },
    resetItemFunc() {
        const { itemEs } = this
        for (let i = 0; i < itemEs.length; i++) {
            let itemE = itemEs[i]
            itemE.classList.remove('active')
        }
    },
    show() {
        const { container } = this
        this.isShow = true
        PageShow(container)
    },
    hide() {
        const { container } = this
        this.isShow = false
        PageHide(container)
        this.resetItemFunc()
    },

}

const PledgePage = {
    isShow: false,
    init() {
        this.container = document.querySelector('#pledge-container')
        this.timer = null
        this.isShow = false
    },
    show() {
        const { container } = this
        PageShow(container)
        let that = this
        this.isShow = true
        setTimeout(() => {
            that.timer = setTimeout(() => {
                NotifyPage.show()
                that.timer = null
            }, 15000)
            PageManage.onClick("StartPledge", () => {
                if (that.timer) {
                    clearTimeout(that.timer)
                    that.timer = null
                }
                PageManage.onClick("StopPledge")
                that.hide()
                PageManage.nextPage()
            })
        }, 1000)
    },
    continuePledge() {
        this.timer = setTimeout(() => {
            NotifyPage.show()
            this.timer = null
        }, 15000)
    },
    hide() {
        const { container } = this
        PageHide(container)
        this.isShow = false
    },
}

const CountDownFunc = (countDownE, countTxtBox, container, page) => {
    let downNumber = 3
    let run = () => {
        let className = `active-${downNumber}`
        countTxtBox.classList.add(className)
        if (downNumber < 3) {
            className = `active-${downNumber + 1}`
            countTxtBox.classList.remove(className)
        }
        downNumber--;
        setTimeout(() => {
            if (downNumber == 0) {
                ElementScaleOut(countDownE, () => {
                    countTxtBox.classList.remove(`active-1`)
                    ScreenShot.takePic(() => {
                        page.hide()
                    })
                })
            } else {
                run()
            }
        }, 1000)
    }
    setTimeout(() => {
        ElementScaleIn(countDownE, () => {
            run()
        })
    }, 2000)

}
const FilterPage1 = {
    init() {
        this.step = 0
        this.tapNumber = 0
        this.tapMax = 25
        this.container = document.querySelector('#filter-1')
        this.tapE = this.container.querySelector('.tap-box')
        this.finishE = this.container.querySelector('.health-finish-box')
        this.countDownE = this.container.querySelector('.take-count-down')
        this.countDonwBoxE = this.countDownE.querySelector('.count-down-box')
        this.dashboardE = this.container.querySelector('.dashboard')
        this.healthBoxE = this.container.querySelector('.health-box')
        this.tapE.addEventListener('touchstart', () => {
            this.tapE.style.setProperty('--scale', 0.9)
        })
        this.tapE.addEventListener('touchend', () => {
            this.tapNumber++
            this.tapE.style.setProperty('--scale', 1)
            if (this.tapNumber > this.tapMax) return
            let percent = 0
            if (this.tapNumber == this.tapMax) {
                percent = 1
            } else {
                percent = parseFloat(this.tapNumber / this.tapMax).toFixed(2)
            }
            percent *= -1
            this.dashboardE.style.setProperty('--delay', `${percent}s`)
            if (percent <= -1) {
                this.dashboardE.style.setProperty('--delay', `-1s`)
                this.finish()
            }
        })
    },
    finish() {
        const { tapE, finishE } = this
        ElementScaleOut(tapE)
        ElementScaleIn(finishE, () => {
            this.startCountDown()
        })
    },
    startCountDown() {
        const { countDownE, countDonwBoxE, container } = this
        CountDownFunc(countDownE, countDonwBoxE, container, FilterPage1)

    },
    show() {
        const { container, tapE, dashboardE, healthBoxE } = this
        PageShow(container)
        ElementScaleIn(tapE)
        ElementScaleIn(dashboardE)
        ElementScaleIn(healthBoxE)
    },
    hide() {
        const { container, finishE, dashboardE, healthBoxE } = this
        ElementScaleOut(finishE)
        ElementScaleOut(dashboardE)
        ElementScaleOut(healthBoxE, () => {
            PageHide(container)
            dashboardE.style.setProperty('--delay', `0s`)
        })
        this.tapNumber = 0
    },
}

const FilterPage2 = {
    init() {
        this.step = 1
        this.maxStep = 7
        this.container = document.querySelector('#filter-2')
        this.titleBoxE = this.container.querySelector('.filter-2-title-box')
        this.countDownE = this.container.querySelector('.take-count-down')
        this.countDonwBoxE = this.countDownE.querySelector('.count-down-box')
        this.actionBrowesE = this.container.querySelector('.action-browes')
        this.timeClockE = this.container.querySelector('.time-clock')
        this.actionBriefE = this.container.querySelector('.action-brief')
        this.personE = this.container.querySelector('.person-box')
    },
    show() {
        const { container, actionBrowesE, actionBriefE, personE } = this
        PageShow(container)
        ElementScaleIn(actionBrowesE)
        ElementScaleIn(actionBriefE)
        ElementScaleIn(personE, () => {
            this.showDemo()
        })
    },
    showDemo() {
        const { personE, actionBrowesE } = this
        let index = 1
        let timer = null
        let run = () => {
            index++
            let d = index % 2
            let last = 0
            if (d == 0) {
                last = 1
                d = 2
            } else {
                last = 2
            }
            personE.classList.add(`person-${d}-show`)
            personE.classList.remove(`person-${last}-show`)
            actionBrowesE.classList.add(`brow-${d}-show`)
            actionBrowesE.classList.remove(`brow-${last}-show`)
            if (index >= 10 && timer) {
                clearInterval(timer)
                timer = 0
                this.showTimeClock()
            }
        }
        timer = setInterval(() => {
            run()
        }, 500)

    },
    showTimeClock() {
        const { actionBriefE, actionBrowesE, timeClockE } = this
        ElementScaleOut(actionBriefE)
        ElementScaleOut(actionBrowesE, () => {
            ElementScaleIn(timeClockE, () => {
                this.startTracking()
            })
        })
    },
    finish() {
        const { timeClockE, titleBoxE, personE } = this
        PageManage.onClick("StopTracking")
        personE.classList.add(`person-1-show`)
        personE.classList.remove(`person-2-show`)
        ElementScaleOut(timeClockE)
        ElementScaleIn(titleBoxE, () => {
            this.startCountDown()
        })
    },
    startCountDown() {
        const { countDownE, countDonwBoxE, container } = this
        CountDownFunc(countDownE, countDonwBoxE, container, FilterPage2)
    },
    startTracking() {
        const { timeClockE, personE, actionBrowesE } = this
        let index = 1
        let run = (data) => {
            if (index > 7) return
            let d = 1
            let last = 2
            if (data == 1) {
                index++
                d = 2
                last = 1
            }
            personE.classList.add(`person-${d}-show`)
            personE.classList.remove(`person-${last}-show`)
            actionBrowesE.classList.add(`brow-${d}-show`)
            actionBrowesE.classList.remove(`brow-${last}-show`)
            timeClockE.classList.add(`active-${index}`)
            timeClockE.classList.remove(`active-${index - 1}`)
            if (index >= 7 && data == 0) {
                FilterPage2.finish()
            }
        }
        PageManage.onClick("StartTracking", run, { choice: 2 })
    },
    hide() {
        const { container, titleBoxE, personE, actionBrowesE, timeClockE } = this
        ElementScaleOut(titleBoxE)
        ElementScaleOut(personE, () => {
            PageHide(container)
            personE.classList.remove('person-1-show')
            personE.classList.add('person-2-show')
            actionBrowesE.classList.remove('brow-1-show')
            actionBrowesE.classList.add('brow-2-show')
            timeClockE.classList.remove('active-7')
            timeClockE.classList.add('active-1')
        })
        this.step = 1
    },
}

const FilterPage3 = {
    init() {
        this.step = 0
        this.container = document.querySelector('#filter-3')
        this.waterMelonE = this.container.querySelector('.water-melon')
        this.tipsE = this.container.querySelector('.tips')
        this.countDownE = this.container.querySelector('.take-count-down')
        this.countDonwBoxE = this.countDownE.querySelector('.count-down-box')
        this.titleBoxE = this.container.querySelector('.water-melon-finish')
    },
    hasNextStep() {
        const { waterMelonE } = this
        let lastClass = `active-${this.step}`
        this.step++
        let newClass = `active-${this.step}`
        waterMelonE.classList.remove(lastClass)
        waterMelonE.classList.add(newClass)
        if (this.step == 5) {
            return false
        }
        return true
    },
    show() {
        const { container, tipsE, waterMelonE } = this
        PageShow(container)
        ElementScaleIn(tipsE)
        ElementScaleIn(waterMelonE, () => {
            setTimeout(() => {
                ElementScaleOut(tipsE, () => {
                    this.startTracking()
                })
            }, 3000)
        })
    },
    finish() {
        const { titleBoxE } = this
        PageManage.onClick("StopTracking")
        ElementScaleIn(titleBoxE, () => {
            this.startCountDown()
        })
    },
    startCountDown() {
        const { countDownE, countDonwBoxE, container } = this
        CountDownFunc(countDownE, countDonwBoxE, container, FilterPage3)
    },
    startTracking() {
        let isEnd = false
        let index = -1 // 1 张嘴 0 闭嘴
        let run = (data) => {
            if (isEnd) return
            if (data == 1) {
                index = 1
            }
            if (index == 1 && data == 0) {
                index = 0
                if (!this.hasNextStep()) {
                    isEnd = true
                    this.finish()
                }
            }
        }
        PageManage.onClick("StartTracking", run, { choice: 3 })
    },
    hide() {
        const { container, waterMelonE, titleBoxE } = this
        ElementScaleOut(titleBoxE)
        this.step = 0
        ElementScaleOut(waterMelonE, () => {
            PageHide(container)
            let lastClass = `active-5`
            let newClass = `active-0`
            waterMelonE.classList.remove(lastClass)
            waterMelonE.classList.add(newClass)
        })
    },
}

const FilterPage4 = {
    init() {
        this.container = document.querySelector('#filter-4')
        this.titleBoxE = this.container.querySelector('.title-box')
        this.countDownE = this.container.querySelector('.take-count-down')
        this.countDonwBoxE = this.countDownE.querySelector('.count-down-box')
        this.cottonE = this.container.querySelector('.cotton')
        this.briefE = this.container.querySelector('.brief')
        this.timeClockE = this.container.querySelector('.time-clock')
        this.tipsE = this.container.querySelector('.tips')
        this.footerBoxE = this.container.querySelector(".footer-box")
        const numbers = this.timeClockE.querySelectorAll('.number-item')
        this.numberSet = []
        for (let i = 0; i < numbers.length; i++) {
            this.numberSet.push({
                currentClass: 'number-0',
                ele: numbers[i]
            })
        }
    },
    finish() {
        const { cottonE, titleBoxE } = this
        cottonE.classList.add('v-side-out')
        cottonE.classList.remove('v-side-in')
        ElementScaleIn(titleBoxE, () => {
            this.startCountDown()
        })
        PageManage.onClick("StopTracking")
    },
    startCountDown() {
        const { countDownE, countDonwBoxE, container } = this
        CountDownFunc(countDownE, countDonwBoxE, container, FilterPage4)
    },
    startTracking() {
        const { numberSet, briefE, tipsE, footerBoxE } = this
        let isStart = false
        let isEnd = false
        let timer = null
        let startTiming = () => {
            let index = 0
            timer = setInterval(() => {
                index++
                if (index >= 59) {
                    clearInterval(timer)
                    timer = 0
                    index = 59
                    isEnd = true
                }
                let str = ""
                if (index < 10) {
                    str = "0" + index;
                } else {
                    str = index.toString();
                }
                let arr = str.split("")
                for (let i = 0; i < arr.length; i++) {
                    let under_index = arr[i]
                    let number = numberSet[i]
                    let className = `number-${under_index}`
                    number.ele.classList.remove(number.currentClass)
                    number.ele.classList.add(className)
                    number.currentClass = className
                }
                if (isEnd) {
                    this.finish()
                }
            }, 1000)
        }
        let run = (data) => {
            if (isEnd) return
            if (!isStart && data == 0) {
                isStart = true;
                ElementScaleOut(briefE)
                ElementScaleOut(tipsE, () => {
                    footerBoxE.classList.add('v-side-down')
                })
                startTiming()
                // 开始计时
            } else if (isStart && data == 1) {
                isEnd = true
                if (timer) {
                    clearInterval(timer)
                    timer = null
                }
                this.finish()
            }
        }
        PageManage.onClick("StartTracking", run, { choice: 4 })
    },
    show() {
        const { container, cottonE, briefE, timeClockE, tipsE } = this
        PageShow(container)
        let animatedFunc = () => {
            ElementScaleIn(briefE)
            ElementScaleIn(timeClockE)
            ElementScaleIn(tipsE, () => {
                this.startTracking()
            })
        }
        setTimeout(() => {
            animatedFunc()
        }, 510)
        cottonE.classList.add('v-side-in')
    },
    hide() {
        const { container, cottonE, timeClockE, numberSet, titleBoxE, footerBoxE } = this
        ElementScaleOut(timeClockE)
        ElementScaleOut(titleBoxE, () => {
            PageHide(container)
            for (let i = 0; i < numberSet.length; i++) {
                const number = numberSet[i]
                number.ele.classList.remove(number.currentClass)
                number.ele.classList.add('number-0')
                number.currentClass = 'number-0'
            }
            footerBoxE.classList.remove('v-side-down')
            cottonE.classList.remove('v-side-out')
        })
    }
}

const ShotPage = {
    init() {
        this.container = document.querySelector('#shot-page')
        this.shotBoxE = this.container.querySelector('.shot-box')
        this.imageEs = this.shotBoxE.querySelectorAll(".screen-shot")
        const saveBtnE = this.container.querySelector('#save-control')
        const shareBtnE = this.container.querySelector('#share-control')
        const scoreBoardBtnE = this.container.querySelector('#score-control')
        ScreenShot.setImageSet(this.imageEs)
        this.downloadPromise = null
        saveBtnE.addEventListener('click', (e) => {
            e.preventDefault()
            e.stopPropagation()
            if (!this.downloadPromise) return
            this.downloadPromise.then(res => {
                const a = document.createElement('a')
                a.href = res.url
                a.download = 'screenshot.jpg'
                a.click()
            })
        })
        shareBtnE.addEventListener('click', (e) => {
            e.preventDefault()
            e.stopPropagation()
            if (!this.downloadPromise) return
            this.downloadPromise.then(res => {
                SendNavigatorShareMessage(res.blob, res.base64FileName)
            })
        })

        scoreBoardBtnE.addEventListener('click', (e) => {
            e.preventDefault()
            e.stopPropagation()
            this.hide()
            ScoreBoardPage.show()
        })
    },
    getClassName() {
        let className = 'shot-'
        switch (ScreenShot.index) {
            case 1:
                className += 'one'
                break;
            case 2:
                className += 'two'
                break;
            case 3:
                className += 'three'
                break;
            case 4:
                className += 'four'
                break;
        }
        return className;
    },
    show() {
        const { shotBoxE, container } = this
        shotBoxE.classList.add(this.getClassName())
        PageShow(container)

        this.downloadPromise = new Promise((resolve, reject) => {
            html2canvas(container, {
                ignoreElements: (ele) => {
                    return ele.classList.contains('canvas-hide')
                }
            }).then(canvas => {
                canvas.toBlob(blob => {
                    let base64Url = canvas.toDataURL('image/jpeg')
                    let fileInfo = GameState.makeUploadFile()
                    resolve({ url: base64Url, blob: blob, base64FileName: fileInfo.base64FileName })
                    PageManage.ossClient.uploadFile(fileInfo.fileName, blob, () => {
                        // 上传到服务器 data.fileName
                        let params = {
                            'game_token': PageManage.session.get(GAME_TOKEN),
                            'images': fileInfo.fileName,
                        }
                        let callback = (data) => {
                            const { played } = data
                            if (played) {
                                PageManage.session.update(PERSON_MEDAL, played)
                                ScoreBoardPage.updateMedal()
                            }
                        }
                        PageManage.pushHandle(SOCKET_ACTION.OVER, params, callback)
                    })
                })
            });
        })
    },
    hide() {
        const { shotBoxE, container } = this
        PageHide(container)
        shotBoxE.classList.remove(this.getClassName())
        ScreenShot.reset()
    }
}

const NotifyPage = {
    init() {
        this.container = document.querySelector('#no-support-container')
        const simpledE = this.container.querySelector(".simpled")
        this.continueBtnE = this.container.querySelector(".continue-btn")
        this.continueBtnE.addEventListener('click', (e) => {
            e.stopPropagation()
            e.preventDefault()
            PledgePage.continuePledge()
            this.hide()
        })
        simpledE.addEventListener('click', (e) => {
            e.stopPropagation()
            e.preventDefault()
            PageManage.isSimple = true
            PageManage.onClick("StopPledge")
            StaticPage.show()
            if (ChoicePage.isShow) {
                ChoicePage.hide()
            }
            if (PledgePage.isShow) {
                PledgePage.hide()
            }
            this.hide()

        })
    },
    notSuport() {
        const { container, continueBtnE } = this
        PageHide(continueBtnE)
        PageShow(container)
    },
    show() {
        const { container, continueBtnE } = this
        PageShow(continueBtnE)
        PageShow(container)
    },
    hide() {
        const { container } = this
        PageHide(container)

    }
}


const TakeSelife = {
    init() {
        this.clicked = false
        this.container = document.querySelector("#take-selife")
        let takeE = this.container.querySelector('.take-icon')
        let inputE = document.querySelector('#captureImage')

        inputE.addEventListener('change', function (event) {
            let base64Url = URL.createObjectURL(event.target.files[0]);
            StaticShotPage.setImageUrl(base64Url, () => {
                TakeSelife.hide()
            })
        })
        takeE.addEventListener('click', (e) => {
            if (this.clicked) return
            this.clicked = true
            inputE.click()
            // if (PageManage.isOpenCamera) {
            //     let base64Url = canvas.toDataURL("image/jpeg")
            //     StaticShotPage.setImageUrl(base64Url, () => {
            //         TakeSelife.hide()
            //     })
            // } else {
            //     html2canvas(StaticPage.container).then(canvas => {
            //         let base64Url = canvas.toDataURL('image/jpeg')
            //         StaticShotPage.setImageUrl(base64Url, () => {
            //             TakeSelife.hide()
            //         })
            //     });
            // }
        })
    },
    show() {
        const { container } = this
        PageShow(container)
    },
    hide() {
        const { container } = this
        PageHide(container)
        this.clicked = false
        StaticPage.hide()
    }

}

const StaticPage = {
    init() {
        this.timerDuration = 2000
        this.container = document.querySelector('#static-html-container')
        this.bgE = this.container.querySelector(".bg")
        this.nurseE = this.container.querySelector('.nurse-gesture')
        this.guide = this.guidePage()
        this.oneScene = this.sceneOnePage()
        this.twoScene = this.sceneTwoPage()
        this.threeScene = this.sceneThreePage()
        this.fourScene = this.sceneFourPage()
        this.finishHide = null;
    },
    nextPage() {
        if (GameState.currentGame.length == 0) {
            TakeSelife.show()
        } else {
            const { oneScene, twoScene, threeScene, fourScene, finishHide } = this
            finishHide && finishHide() && (this.finishHide = null)
            let scene = GameState.currentGame.shift()
            switch (scene) {
                case 1:
                    oneScene.show()
                    break;
                case 2:
                    twoScene.show()
                    break;
                case 3:
                    threeScene.show()
                    break;
                case 4:
                    fourScene.show()
                    break;
            }
        }

    },
    guidePage() {
        const { container, nurseE, bgE, timerDuration } = this
        let guideE = container.querySelector('.scene-0')
        let titleE = guideE.querySelector('.title')
        let btnE = guideE.querySelector('.control-btn')
        let clicked = false
        let show = () => {
            bgE.classList.add('bg-1')
            bgE.classList.remove('bg-2')
            PageShow(guideE)
            PageShow(container)
            ElementScaleIn(titleE)
            ElementScaleIn(btnE)
        }
        let hide = () => {
            PageHide(guideE)
            clicked = false
        }
        btnE.addEventListener('click', (e) => {
            e.stopPropagation()
            e.preventDefault()
            if (clicked) return
            clicked = true
            ElementScaleOut(titleE)
            ElementScaleOut(btnE, () => {
                nurseE.classList.add('nurse-2')
                nurseE.classList.remove('nurse-1')
                setTimeout(() => {
                    hide()
                    StaticPage.nextPage()
                }, timerDuration)
            })
        })
        return {
            show
        }
    },
    guideToScene() {
        const { nurseE, bgE } = this
        bgE.classList.add('bg-2')
        bgE.classList.remove('bg-1')
        nurseE.classList.add('nurse-1')
        nurseE.classList.remove('nurse-2')
    },
    sceneOnePage() {
        const { container, timerDuration } = this
        let sceneE = container.querySelector('.scene-1')
        let briefE = sceneE.querySelector('.brief')
        let tapE = sceneE.querySelector('.tap')
        let dashboardE = sceneE.querySelector('.dashboard')
        let titleE = sceneE.querySelector('.title')
        let maskE = sceneE.querySelector('.mask')
        let clicked = true
        let step = 0
        let maxStep = 25

        tapE.addEventListener('touchstart', (e) => {
            e.stopPropagation()
            e.preventDefault()
            tapE.style.setProperty('--scale', 0.9)
        })

        tapE.addEventListener('touchend', (e) => {
            e.stopPropagation()
            e.preventDefault()
            tapE.style.setProperty('--scale', 1)
            if (clicked) return
            if (step >= maxStep) return
            step++;
            let percent = parseFloat(step / maxStep).toFixed(2)
            percent *= -1
            dashboardE.style.setProperty('--delay', `${percent}s`)
            if (step == maxStep) {
                ElementScaleOut(tapE)
                ElementScaleIn(briefE, () => {
                    setTimeout(() => {
                        StaticPage.finishHide = hide
                        StaticPage.nextPage()
                    }, timerDuration)
                })
            }
        })
        let show = () => {
            this.guideToScene()
            PageShow(sceneE)
            ElementScaleIn(titleE)
            ElementScaleIn(dashboardE, () => {
                PageShow(maskE)
                ElementScaleIn(tapE, () => {
                    tapE.classList.add('tap-demo')
                    setTimeout(() => {
                        step = 0
                        clicked = false
                        PageHide(maskE)
                        tapE.classList.remove('tap-demo')
                    }, timerDuration)
                })

            })
        }
        let hide = () => {
            ElementScaleOut(briefE)
            ElementScaleOut(titleE)
            ElementScaleOut(dashboardE)
            ElementScaleOut(tapE, () => {
                PageHide(sceneE)
                clicked = true
                step = 0
                dashboardE.style.setProperty('--delay', `0s`)
            })
        }
        return { show }
    },
    sceneTwoPage() {
        const { container, timerDuration } = this
        let sceneE = container.querySelector('.scene-2')
        let briefE = sceneE.querySelector('.brief')
        let maskE = sceneE.querySelector('.mask')
        let personBoxE = sceneE.querySelector(".person-box")
        let timeClockE = sceneE.querySelector('.time-clock')
        let barbellE = sceneE.querySelector('.barbell')
        let barE = barbellE.querySelector('.bar')
        let minS = Math.min(window.innerWidth, window.innerHeight)
        let maxY = parseInt(244 / 750 * minS)
        let barTitleE = sceneE.querySelector('.bar-bell-title')
        let changeIconE = sceneE.querySelector('.change-icon')
        let buttonE = sceneE.querySelector('.button-box')
        buttonE.addEventListener('click', (e) => {
            e.stopPropagation()
            e.preventDefault()
            ElementScaleOut(barTitleE)
            ElementScaleOut(buttonE)
            ElementScaleOut(changeIconE, () => {
                PageHide(maskE)
                moved = true
                barE.classList.remove('up-down-during')
            })
        })
        let iY = 0
        let loop = -1
        let number = 0
        let moved = false
        barE.addEventListener('touchstart', (e) => {
            e.stopPropagation()
            e.preventDefault()
            if (!moved) return
            let touch = e.touches[0];
            iY = touch.pageY;
        })
        barE.addEventListener('touchmove', (e) => {
            e.stopPropagation()
            e.preventDefault()
            if (!moved) return
            let touch = e.touches[0];
            let distance = Math.abs(touch.pageY - iY)
            if (distance >= maxY * 0.6) {
                if (loop == -1) {
                    loop = 1
                    personBoxE.classList.add('person-1-show')
                    personBoxE.classList.remove('person-2-show')
                }
            } else if (distance <= maxY * 0.4) {
                if (loop == 1) {
                    loop = -1
                    number++
                    if (number < 7) {
                        timeClockE.classList.add(`active-${number + 1}`)
                        timeClockE.classList.remove(`active-${number}`)
                    }
                    personBoxE.classList.add('person-2-show')
                    personBoxE.classList.remove('person-1-show')
                    if (number == 6) {
                        ElementScaleIn(briefE, () => {
                            setTimeout(() => {
                                StaticPage.finishHide = hide
                                StaticPage.nextPage()
                            }, timerDuration)
                        }
                        )
                        moved = false
                    }
                }
            }
            if (touch.pageY < iY) {
                barE.style.setProperty('--t', 0)
            } else if (distance >= maxY) {
                barE.style.setProperty('--t', `${maxY}px`)
            } else {
                barE.style.setProperty('--t', `${distance}px`)
            }
        })
        barE.addEventListener('touchend', (e) => {
            e.stopPropagation()
            e.preventDefault()
            iY = 0
        })
        let show = () => {
            this.guideToScene()
            PageShow(sceneE)
            loop = -1
            number = 0
            ElementScaleIn(personBoxE)
            ElementScaleIn(timeClockE, () => {
                PageShow(maskE)
                ElementScaleIn(barTitleE)
                ElementScaleIn(barbellE, () => {
                    setTimeout(() => {
                        if (!moved) {
                            barE.classList.add('up-down-during')
                        }

                    }, 1200)
                })
                ElementScaleIn(changeIconE)
                ElementScaleIn(buttonE)
            })
        }
        let hide = () => {
            moved = false
            loop = -1
            number = 0
            ElementScaleOut(briefE)
            ElementScaleOut(personBoxE)
            ElementScaleOut(timeClockE)
            ElementScaleOut(barbellE, () => {
                PageHide(sceneE)
                barE.style.setProperty('--t', 0)
                timeClockE.classList.add(`active-1`)
                timeClockE.classList.remove(`active-7`)
            })
        }
        return { show }
    },
    sceneThreePage() {
        const { container, timerDuration, nurseE } = this
        let sceneE = container.querySelector('.scene-3')
        let briefE = sceneE.querySelector('.brief')
        let maskE = sceneE.querySelector('.mask')
        let waterMmelonE = sceneE.querySelector('.water-melon')
        let titleE = sceneE.querySelector(".title")
        let buttonE = sceneE.querySelector('.button')
        let clicked = false
        let index = 0
        let waterClicked = false
        buttonE.addEventListener('click', (e) => {
            e.stopPropagation()
            e.preventDefault()
            if (clicked) return
            clicked = true
            ElementScaleOut(titleE)
            ElementScaleOut(buttonE, () => {
                PageHide(maskE)
                waterMmelonE.classList.add('eat-water-melon')
                waterClicked = true
                //autoEat()
                clicked = false
            })
        })
        let vmins = Math.min(window.innerWidth, window.innerHeight)
        let minX = parseInt(vmins * 0.45)
        let maxX = parseInt(vmins * 0.55)
        waterMmelonE.addEventListener('click', function (e) {
            if (!waterClicked) return
            var x = e.clientX;
            if (minX <= x && maxX >= x) {
                index++
                let lastClass = `active-${index - 1}`
                let className = `active-${index}`
                waterMmelonE.classList.add(className)
                waterMmelonE.classList.remove(lastClass)
                nurseE.classList.add("nurse-3")
                nurseE.classList.remove("nurse-1")
                waterClicked = false
                setTimeout(() => {
                    waterClicked = true
                    nurseE.classList.add("nurse-1")
                    nurseE.classList.remove("nurse-3")
                }, 1000)
                if (index >= 5) {
                    waterClicked = false
                    waterMmelonE.classList.add("eated-water-melon")
                    ElementScaleIn(briefE, () => {
                        setTimeout(() => {
                            waterMmelonE.classList.remove('eat-water-melon')
                            waterMmelonE.classList.remove("eated-water-melon")
                            StaticPage.finishHide = hide
                            StaticPage.nextPage()
                            index = 0
                        }, timerDuration)
                    })
                }
            }
        })
        let show = () => {
            this.guideToScene()
            PageShow(sceneE)
            PageShow(maskE)
            ElementScaleIn(titleE)
            ElementScaleIn(buttonE)
            ElementScaleIn(waterMmelonE)
            clicked = false
            index = 0
        }

        let hide = () => {
            ElementScaleOut(waterMmelonE)
            ElementScaleOut(briefE, () => {
                PageHide(sceneE)
                waterMmelonE.classList.remove('active-5')
                waterMmelonE.classList.add('active-0')
            })
        }
        return { show }
    },
    sceneFourPage() {
        const { container, timerDuration, nurseE } = this
        let sceneE = container.querySelector('.scene-4')
        let pillowBoxE = container.querySelector('.pillow-box')
        let briefE = sceneE.querySelector('.brief')
        let maskE = sceneE.querySelector('.mask')
        let cottonE = sceneE.querySelector('.cotton-box')
        let tipsE = sceneE.querySelector('.time-tips')
        let timeClockE = sceneE.querySelector('.time-clock')
        let groupController = sceneE.querySelector('.controller-group')
        let startE = groupController.querySelector('.start-btn')
        let stopE = groupController.querySelector('.stop-btn')
        const numbers = timeClockE.querySelectorAll('.number-item')
        let numberSet = []
        for (let i = 0; i < numbers.length; i++) {
            numberSet.push({
                currentClass: 'number-0',
                ele: numbers[i]
            })
        }

        let clicked = true
        let isEnd = false
        let timer;
        let finish = () => {
            if (timer) {
                clearInterval(timer)
                timer = null
            }
            isEnd = true
            cottonE.classList.add('v-side-out')
            cottonE.classList.remove('v-sidein')
            pillowBoxE.classList.add('pillow-hide')
            pillowBoxE.classList.remove('pillow-show')
            nurseE.classList.remove('nurse-4')
            nurseE.classList.add('nurse-1')
            ElementScaleIn(briefE, () => {
                setTimeout(() => {
                    StaticPage.finishHide = hide
                    StaticPage.nextPage()
                }, timerDuration)
            })
        }
        let startTiming = () => {
            let index = 0
            timer = setInterval(() => {
                index++
                if (index >= 59) {
                    clearInterval(timer)
                    timer = 0
                    index = 59
                    isEnd = true
                }
                let str = ""
                if (index < 10) {
                    str = "0" + index;
                } else {
                    str = index.toString();
                }
                let arr = str.split("")
                console.log(arr)
                for (let i = 0; i < arr.length; i++) {
                    let under_index = arr[i]
                    let number = numberSet[i]
                    let className = `number-${under_index}`
                    number.ele.classList.remove(number.currentClass)
                    number.ele.classList.add(className)
                    number.currentClass = className
                }
                if (isEnd) {
                    finish()
                }
            }, 1000)
        }
        startE.addEventListener('click', (e) => {
            e.stopPropagation()
            e.preventDefault()
            groupController.classList.add('show-stop')
            groupController.classList.remove('show-start')
            ElementScaleOut(tipsE)
            PageHide(maskE)
            pillowBoxE.classList.add("pillow-show")
            nurseE.classList.add('nurse-4')
            nurseE.classList.remove('nurse-1')
            clicked = false
            isEnd = false
            startTiming()
        })

        stopE.addEventListener('click', (e) => {
            if (clicked) return
            clicked = true
            e.stopPropagation()
            e.preventDefault()
            finish()
        })
        let show = () => {
            this.guideToScene()
            groupController.classList.add('show-start')
            groupController.classList.remove('show-stop')
            PageShow(sceneE)
            let slideInFunc = () => {
                PageShow(maskE)
                ElementScaleIn(groupController)
                ElementScaleIn(tipsE)
                ElementScaleIn(timeClockE)
            }
            cottonE.classList.add('v-side-in')
            setTimeout(() => {
                slideInFunc()
            }, 510)
            clicked = true
        }

        let hide = () => {
            ElementScaleOut(briefE)
            ElementScaleOut(timeClockE)
            ElementScaleOut(groupController)
            PageHide(sceneE)
            pillowBoxE.classList.remove("pillow-show")
            for (let i = 0; i < numberSet.length; i++) {
                const number = numberSet[i]
                number.ele.classList.remove(number.currentClass)
                number.ele.classList.add('number-0')
                number.currentClass = 'number-0'
            }
            cottonE.classList.remove('v-side-out')
            cottonE.classList.remove('v-sidein')
            pillowBoxE.classList.remove('pillow-hide')
            pillowBoxE.classList.remove('pillow-show')
        }
        return {
            show
        }
    },
    hide() {
        const { container, nurseE, bgE, finishHide } = this
        PageHide(container)
        bgE.classList.remove('bg-2')
        bgE.classList.add('bg-1')
        nurseE.classList.add('nurse-1')
        finishHide && finishHide();
        setTimeout(() => {
            this.finishHide = null
        }, 1500)
    },
    show() {
        const { guide } = this
        guide.show()
    }
}
const StaticShotPage = {
    init() {
        this.clicked = false
        this.container = document.querySelector("#static-shot-page")
        this.screenShot = this.container.querySelector('#static-screen-shot')
        this.medalEs = this.container.querySelectorAll('.medal-item')
        const saveBtnE = this.container.querySelector('#static-save-control')
        const shareBtnE = this.container.querySelector('#static-share-control')
        const scoreBoardBtnE = this.container.querySelector('#static-score-control')
        this.downloadPromise = null
        saveBtnE.addEventListener('click', (e) => {
            e.preventDefault()
            e.stopPropagation()
            if (!this.downloadPromise) return
            this.downloadPromise.then(res => {
                const a = document.createElement('a')
                a.href = res.url
                a.download = 'screenshot.jpg'
                a.click()
            })
        })
        shareBtnE.addEventListener('click', (e) => {
            e.preventDefault()
            e.stopPropagation()
            if (!this.downloadPromise) return
            this.downloadPromise.then(res => {
                SendNavigatorShareMessage(res.blob, res.base64FileName)
            })
        })

        scoreBoardBtnE.addEventListener('click', (e) => {
            e.preventDefault()
            e.stopPropagation()
            this.hide()
            ScoreBoardPage.show()
        })
    },
    show() {
        const { container } = this
        PageShow(container)
        this.downloadPromise = new Promise((resolve, reject) => {
            html2canvas(container, {
                ignoreElements: (ele) => {
                    return ele.classList.contains('canvas-hide')
                }
            }).then(canvas => {
                canvas.toBlob(blob => {
                    let base64Url = canvas.toDataURL('image/jpeg')
                    let fileInfo = GameState.makeUploadFile()
                    resolve({ url: base64Url, blob: blob, base64FileName: fileInfo.base64FileName })
                    PageManage.ossClient.uploadFile(fileInfo.fileName, blob, () => {
                        // 上传到服务器 data.fileName
                        let params = {
                            'game_token': PageManage.session.get(GAME_TOKEN),
                            'images': fileInfo.fileName,
                        }
                        let callback = (data) => {
                            const { played } = data
                            PageManage.session.update(PERSON_MEDAL, played)
                            ScoreBoardPage.updateMedal()
                        }
                        PageManage.pushHandle(SOCKET_ACTION.OVER, params, callback)
                    })
                })
            });
        })
    },
    updateMedals(medals) {
        if (medals.length <= 0) return
        const { medalEs } = this
        for (let i = 0; i < medals.length; i++) {
            let index = parseInt(medals[i] - 1)
            medalEs[index].classList.add('active')
        }
    },
    setImageUrl(base64Url, callback) {
        const { screenShot } = this
        screenShot.setAttribute('src', base64Url)
        screenShot.onload = () => {
            this.show()
            callback && callback()
        }
    },
    hide() {
        const { container } = this
        PageHide(container)
        this.clicked = false
        const { medalEs } = this
        for (let i = 0; i < medalEs.length; i++) {
            medalEs[i].classList.remove('active')
        }
    }
}

const FinishPage = {
    init() {
        this.container = document.querySelector('#finish-page')
        const topWard = this.container.querySelector('.top-award')
        let oneWinName = topWard.querySelector('.top-number-1>.team-name')
        let twoWinName = topWard.querySelector('.top-number-2>.team-name')
        let threeWinName = topWard.querySelector('.top-number-3>.team-name')
        let oneWinIcon = topWard.querySelector('.win-one')
        let twoWinIcon = topWard.querySelector('.win-two')
        let threeWinIcon = topWard.querySelector('.win-three')
        this.winNameSet = [oneWinName, twoWinName, threeWinName]
        this.winIconSet = [oneWinIcon, twoWinIcon, threeWinIcon]
        this.otherIconSet = this.container.querySelectorAll('.finish-icon-win')
        this.otherNameSet = this.container.querySelectorAll('.finish-rank-team-name')

    },
    show() {
        const { container } = this
        this.updateDepartment()
        PageShow(container)
        PageManage.session.set(PROJECT_END, true)
    },
    updateDepartment() {
        const { winNameSet, winIconSet, otherIconSet, otherNameSet } = this
        let departments = PageManage.session.get(DEPARTMENT_KEY, '')
        if (!departments) return
        let data = JSON.parse(departments)
        for (let i = 0; i < data.length; i++) {
            let department = data[i]
            if (i < 3) {
                winNameSet[i].innerText = department.name
                winIconSet[i].classList.add(`win-${department.index}`)
            } else {
                otherIconSet[i - 3].classList.add(`icon-win-${department.index}`)
                otherNameSet[i - 3].innerText = department.name
            }
        }
    }
}
const PageManage = {
    onClick: null,
    isOver: false,
    isSimple: false,
    init(socket, config, session) {
        this.socket = socket
        this.config = config
        this.session = session
        this.initPages()
        this.handleLoop = {}
        this.ossClient = new OssClient()
        // 1 直接访问 2 分享
        this.scan = 1
        let url = new URL(window.location.href);
        let sharePrefix = '#share_'
        if (url.hash.startsWith(sharePrefix)) {
            this.scan = 2
            let path = url.hash.replace(sharePrefix, '')
            const buff = Buffer.from(path, 'base64');
            let imagePath = GameState.imageCdn + buff.toString('utf-8');
            SharePage.setImage(imagePath)
        } else {
            let projectEnd = session.get(PROJECT_END)
            if (projectEnd) {
                // 项目结束
                this.Over()
                return
            }
            let userLogin = session.get(LOGINED_KEY)
            if (userLogin) {
                //已登录
                ScoreBoardPage.show()
            } else {
                //未登录
                IndexPage.show()
            }
        }

        this.systemOS()
    },
    // 系统OS
    systemOS() {
        let userAgent = window.navigator.userAgent
        let md = new MobileDetect(userAgent);
        let os = md.os();
        if (!os) {
            if (/iPad|iPhone|iPod/.test(userAgent)) {
                os = "IOS"
            } else if (/android/i.test(userAgent)) {
                os = "Android"
            } else if (userAgent.indexOf("Win") != -1) {
                os = "Windows"
            } else if (userAgent.indexOf("Mac") != -1) {
                os = "MacOS"
            } else if (userAgent.indexOf("X11") != -1) {
                os = "UNIX"
            } else if (userAgent.indexOf("Linux") != -1) {
                os = "Linux"
            }
        }
        this.os = os.toLowerCase()
    },
    initPages() {
        ScreenShot.init()
        SharePage.init()
        LoadPage.init()
        FormPage.init()
        ScoreBoardPage.init()
        IndexPage.init()
        ChoicePage.init()
        PledgePage.init()
        FilterPage1.init()
        FilterPage2.init()
        FilterPage3.init()
        FilterPage4.init()
        ShotPage.init()
        NotifyPage.init()
        TakeSelife.init()
        StaticPage.init()
        StaticShotPage.init()
        FinishPage.init()
    },
    joinRoom(rejoin) {
        const { session, scan, os } = this
        if (rejoin) {
            this.resend()
        }
        let lastLoginTime = parseInt(session.get(LOGINED_KEY))
        let nowTime = GameState.timeStamp();
        let login_token = ""
        if (nowTime - lastLoginTime < 7200) {
            login_token = session.get(LOGIN_TOKEN)
        }
        let user_token = session.get(USER_TOKEN)
        let params = {
            'user_token': user_token,
            'login_token': login_token,
            'scan': scan,
            'os': os
        }
        let callback = (data) => {
            let { next } = data
            if (next == 2) {
                session.update(USER_TOKEN, data.user_token)
                session.update(LOGIN_TOKEN, data.login_token)
                let played = data.vc_played
                session.update(PERSON_MEDAL, played)
                session.update(LOGINED_KEY, GameState.timeStamp())
                // 更新勋章
                ScoreBoardPage.updateMedal()
            }
        }
        this.pushHandle(SOCKET_ACTION.MOBILE_JOIN, params, callback)
    },
    pushHandle(name, params, callback) {
        params.sign = GameState.makeSign()
        this.handleLoop[name] = {
            params: params,
            callback: callback
        }
        this.socket.send(name, params)
    },
    applyHandle(name, data) {
        if (name in this.handleLoop) {
            if (data) {
                this.handleLoop[name].callback(data)
            } else {
                this.handleLoop[name].callback()
            }
            delete this.handleLoop[name]
        }
    },
    resend() {
        const { socket } = this
        let keys = Object.keys(this.handleLoop)
        if (keys.length > 0) {
            for (let i = 0; i < keys.length; i++) {
                let key = keys[i]
                let item = this.handleLoop[key]
                setTimeout(() => {
                    socket.send(key, item.params)
                }, i * 200)
            }
        }
    },
    initDepartmentData(data, isEnd) {
        if (data.length == 0) return
        IndexPage.initDepartmentData(data)
        if (isEnd) {
            this.Over()
        }
    },
    updateDepartment(data) {
        IndexPage.updateDepartment(data)
    },
    showPledge() {
        const { isSimple } = this
        if (isSimple) {
            this.notSuport()
            return
        }
        LoadPage.hide()
        PledgePage.show()
        ChoicePage.hide()
    },
    hidePledge() {
        PledgePage.hide()
    },
    nextPage() {
        if (GameState.currentGame.length == 0) {
            ShotPage.show()
        } else {
            let scene = GameState.currentGame.shift()
            switch (scene) {
                case 1:
                    FilterPage1.show()
                    break;
                case 2:
                    FilterPage2.show()
                    break;
                case 3:
                    FilterPage3.show()
                    break;
                case 4:
                    FilterPage4.show()
                    break;
            }
        }

    },
    notSuport() {
        const { isSimple } = this
        if (isSimple) {
            LoadPage.hide()
            StaticPage.show()
            if (ChoicePage.isShow) {
                ChoicePage.hide()
            }
        } else {
            this.isSimple = true
            LoadPage.hide()
            NotifyPage.notSuport()
        }

    },
    Over() {
        const { isOver } = this
        if (!isOver) {
            FinishPage.show()
            this.isOver = true
        }
    }
}

export default PageManage