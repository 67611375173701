class Socket {

    constructor(address, openCallback, receiveCallback, closeCallback, errorCallback) {
        this.address = address;
        this.openCallback = openCallback;
        this.receiveCallback = receiveCallback;
        this.closeCallback = closeCallback;
        this.errorCallback = errorCallback;
    }
    connect() {
        this.socket = new WebSocket(this.address);
        if (this.openCallback != null) {
            this.socket.onopen = this.openCallback;
        }
        if (this.receiveCallback != null) {
            this.socket.onmessage = this.receiveCallback;
        }
        if (this.closeCallback != null) {
            this.socket.onclose = this.closeCallback;
        }
        if (this.errorCallback != null) {
            this.socket.onerror = this.errorCallback;
        }
    }
    reconnect() {
        this.connect();
    }
    send(status, data = {}) {
        let message = {
            status: status,
            data: data
        }
        try {
            this.socket.send(JSON.stringify(message));
        } catch (e) {

        }

    }
}
export default Socket;