class WebCamera {
    constructor(videoContainer,front = false, isIos = true, canvasContainer = null) {
        this.videoContainer = videoContainer;
        this.canvas = canvasContainer ? canvasContainer : document.createElement('canvas');
        this.ctx = this.canvas.getContext('2d',{ willReadFrequently: true });
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.webAspect = window.innerWidth / window.innerHeight;
        this.accessPermissions = window.navigator.permissions && window.navigator.permissions.query
        this.accessDevices = window.navigator.mediaDevices.enumerateDevices
        this.ios = isIos
        this.front = front
    }
    openCamera(successCallBack = null, failCallBack = null) {
        const { accessPermissions, accessDevices, ios, front, videoContainer, webAspect, ctx, canvas } = this
        if (!accessPermissions || !accessDevices) {
            failCallBack && failCallBack()
        }
        let videoAspect = 1
        let rect = { x: 0, y: 0, width: 0, height: 0 }
        let frameAnimate = (isGet) => {
            ctx.clearRect(0, 0, canvas.width, canvas.height)
            ctx.drawImage(videoContainer, rect.x, rect.y, rect.width, rect.height,0,0,canvas.width,canvas.height)
            if(isGet){
               return ctx.getImageData(0,0,canvas.width, canvas.height);
            }
            return null;
        }
        window.navigator.permissions.query({ name: 'camera' }).then(permission => {
            if (permission && permission.state === 'denied') {
                failCallBack && failCallBack()
                return
            }
            window.navigator.mediaDevices.enumerateDevices().then(devices => {
                devices = devices.filter(d => d.kind === 'videoinput');
                if (devices.length <= 0) {
                    failCallBack && failCallBack()
                    return false
                }
                let deviceId = devices[0].deviceId
                let configuration = {}
                if (ios) {
                    let facingMode = 'environment'
                    if (front) {
                        facingMode = 'user'
                    }
                    configuration = {
                        audio: false,
                        video: {
                            facingMode: facingMode
                        }
                    }
                } else {
                    if (front) {
                        devices = devices.filter(d => (d.label.toLowerCase().indexOf("front") > -1))
                        deviceId = devices.length > 0 ? devices[0].deviceId : deviceId
                    } else {
                        devices = devices.filter(d => (d.label.toLowerCase().indexOf("back") > -1))
                        deviceId = devices.length > 0 ? devices[devices.length - 1].deviceId : deviceId
                    }
                    configuration = {
                        audio: false,
                        video: {
                            optional: [{ sourceId: deviceId }],
                        }
                    }
                }
                window.navigator.mediaDevices.getUserMedia(configuration).then((stream) => {
                    const track = stream.getVideoTracks()[0];
                    if (typeof track === 'undefined') {
                        failCallBack && failCallBack()
                        return
                    }
                    videoContainer.setAttribute('autoplay', 'autoplay')
                    videoContainer.setAttribute('playsinline', 'playsinline')
                    videoContainer.setAttribute('webkit-playsinline', 'webkit-playsinline')
                    videoContainer.setAttribute('muted', true)
                    videoContainer.srcObject = stream
                    videoContainer.width = 1
                    videoContainer.height = 1
                    videoContainer.onloadeddata = () => {
                        videoAspect = videoContainer.videoWidth / videoContainer.videoHeight
                        if (webAspect > videoAspect) {
                            canvas.width = videoContainer.videoWidth
                            canvas.height = canvas.width / webAspect
                            rect.x = 0
                            rect.y = (videoContainer.videoHeight - canvas.height) / 2
                        } else {
                            canvas.height = videoContainer.videoHeight
                            canvas.width = canvas.height * webAspect
                            rect.x = (videoContainer.videoWidth - canvas.width) / 2
                            rect.y = 0
                        }
                        rect.width = canvas.width
                        rect.height = canvas.height
                        videoContainer.play()
                        successCallBack && successCallBack(frameAnimate)
                    }
                }).catch((error) => {
                    failCallBack && failCallBack()
                })
            })
        }).catch(err => {
            failCallBack && failCallBack()
        })
    }
}

export default WebCamera