 const SOCKET_ACTION = {
    PING: 'ping',
    WELCOME: "welcome",
    MOBILE_JOIN: 'mobile-join',
    PC_JOIN: 'pc-join',
    SIGN: 'sign',
    CHOISE_MENU: 'chose-menu',
    INCREASE: 'increase',
    REFLUSH: 'reflush',
    OVER:"game-over",
    END:"project-end"
}
export default SOCKET_ACTION;